import { Text } from "@clipboard-health/ui-react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { startCase } from "lodash";
import { Controller, useForm } from "react-hook-form";

export interface SignatureConfirmationSignerNameProps {
  onContinue: (name: string, isChargeNurse: boolean) => void;
  disabledContinueButton: boolean;
  shiftId?: string;
}

export const SignatureConfirmationSignerName = (props: SignatureConfirmationSignerNameProps) => {
  const { onContinue, disabledContinueButton, shiftId } = props;
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      name: "",
      isChargeNurse: "",
    },
    mode: "onChange",
  });

  const getToggleValue = (value?: string) => {
    if (!value) {
      return "";
    }
    return value;
  };

  return (
    <Box
      component={Paper}
      elevation={0}
      sx={{
        maxWidth: 400,
        mx: "auto",
        p: 3,
        borderRadius: 2,
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          logEvent(APP_V2_USER_EVENTS.SIGNING_FLOW_V2_SIGNER_NAME_SUBMITTED, {
            isChargeNurse: data.isChargeNurse,
            signerName: data.name,
            shiftId,
          });

          onContinue(startCase(data.name), data.isChargeNurse === "yes");
        })}
      >
        <Text variant="body1" sx={{ mb: 2 }}>
          Please ask the <strong>charge nurse or nursing supervisor you worked with</strong> to
          verify your times and sign:
        </Text>

        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              placeholder="Signer's name"
              fullWidth
              sx={{ mb: 2 }}
              error={!!error}
              helperText={error?.message || ""}
            />
          )}
        />

        <Stack sx={{ mb: 3 }} direction="row" spacing={2}>
          <Text>The signer is the charge nurse or nursing supervisor</Text>
          <Controller
            name="isChargeNurse"
            control={control}
            rules={{ required: "Please select an option" }}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                exclusive
                value={getToggleValue(field.value)}
                aria-label="charge nurse verification"
              >
                <ToggleButton
                  value="yes"
                  sx={{
                    flex: 1,
                    "&.Mui-selected": {
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.primary.contrastText,
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                      },
                    },
                  }}
                  size="small"
                >
                  YES
                </ToggleButton>
                <ToggleButton
                  value="no"
                  sx={{
                    flex: 1,
                    "&.Mui-selected": {
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.primary.contrastText,
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                      },
                    },
                  }}
                  size="small"
                >
                  NO
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Stack>

        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{
            textTransform: "none",
            borderRadius: 28,
            py: 1.5,
            fontSize: "1rem",
          }}
          disabled={disabledContinueButton || !isValid}
        >
          Continue
        </Button>
      </form>
    </Box>
  );
};
