import { Container } from "@mui/material";
import { WorkedBreakConfirmation } from "@src/app/hcpShifts/components/workedBreakConfirmation";
import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "@src/app/hcpShifts/constants";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useRevampWorkplaceSigningFlow } from "@src/appV2/Shifts/Shift/useRevampWorkplaceSigningFlow";
import { WorkedBreakAuthorizationParams } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { Shift, TimeRange } from "@src/lib/interface/src";
import { useState } from "react";

import { SignatureConfirmationSignerName } from "./SignatureConfirmationSignerName";
import { WorkplaceSignoutModalContent } from "./workplaceSignoutModalContent";

export interface SignatureConfirmationProps {
  onSubmit: (file: SelectedFile, facilityEmployeeName: string) => void;
  lunchInOutTimeRange: TimeRange;
  isUploadingImage: boolean;
  isShiftTimeEditOpen: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  workedBreakAuthorization?: WorkedBreakAuthorizationParams;
  onStepChange: (currentStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  onOpenTimeEdit: () => void;
  isPassPhoneOpen: boolean;
  clockInOut: TimeRange;
  shift: Shift;
  setIsOnFinalStageOfSigningFlow: (value: boolean) => void;
}

export function SignatureConfirmationRevampV2(props: SignatureConfirmationProps) {
  const {
    onSubmit,
    lunchInOutTimeRange,
    isUploadingImage,
    isShiftTimeEditOpen,
    isSolveUnpaidBreaksEnabled,
    workedBreakAuthorization,
    onStepChange,
    onOpenTimeEdit,
    isPassPhoneOpen,
    clockInOut,
    shift,
    setIsOnFinalStageOfSigningFlow,
  } = props;

  const [facilityEmployeeName, setFacilityEmployeeName] = useState<string>("");
  const [isWorkedBreakStatementConfirmed, setIsWorkedBreakStatementConfirmed] = useState(false);
  const [isSignedByChargeNurse, setIsSignedByChargeNurse] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<DIGITAL_TIMESHEET_SIGNATURE_STEPS>(
    DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
  );
  useLogEffect(APP_V2_USER_EVENTS.NEW_SIGNING_FLOW_WITH_FEEDBACK_MODAL_OPENED, {
    shiftId: shift._id,
    facilityId: shift.facilityId,
    version: "version_2",
    message: "This flow include signer name and signature",
  });
  const { useRevampedWorkplaceSignoutIsEnabled } = useRevampWorkplaceSigningFlow(
    shift.facilityId ?? ""
  );

  const goToStep = (step: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => {
    setCurrentStep(step);
    onStepChange(step);
  };

  if (isPassPhoneOpen) {
    return null;
  }

  return (
    <Container>
      {currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE && (
        <SignatureConfirmationSignerName
          onContinue={(name: string, isChargeNurse: boolean) => {
            setFacilityEmployeeName(name);
            setIsSignedByChargeNurse(isChargeNurse);
            goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE);
          }}
          disabledContinueButton={isSolveUnpaidBreaksEnabled && isShiftTimeEditOpen}
          shiftId={shift._id}
        />
      )}

      {workedBreakAuthorization &&
        currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION && (
          <WorkedBreakConfirmation
            isTimeEditOpen={isShiftTimeEditOpen}
            lunchInOutTimeRange={lunchInOutTimeRange}
            workedBreakAuthorization={workedBreakAuthorization}
            onEditTimesClick={onOpenTimeEdit}
            onConfirmClick={() => {
              setIsWorkedBreakStatementConfirmed(true);
              setCurrentStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE);
              setIsOnFinalStageOfSigningFlow(true);
            }}
          />
        )}

      {(currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE ||
        (currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION &&
          !workedBreakAuthorization)) && (
        <WorkplaceSignoutModalContent
          onSubmit={(file: SelectedFile) => {
            onSubmit(file, facilityEmployeeName);
          }}
          onStrokeStarted={() => {
            if (workedBreakAuthorization && !isWorkedBreakStatementConfirmed) {
              goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION);
            }
          }}
          onBackClick={() => {
            setIsWorkedBreakStatementConfirmed(false);
            goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE);
          }}
          isUploadingImage={isUploadingImage}
          clockInOut={clockInOut}
          lunchInOut={lunchInOutTimeRange}
          facilityEmployeeName={facilityEmployeeName}
          shift={shift}
          disabledQuestion={!useRevampedWorkplaceSignoutIsEnabled}
          isSignedByChargeNurse={isSignedByChargeNurse}
        />
      )}
    </Container>
  );
}
